import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A Registration Authority sits in front of a Certificate Authority (CA) and provides an authentication and authorization layer.
This guide will show you how to set up an ACME Registration Authority (RA) backed by a `}<a parentName="p" {...{
        "href": "https://cloud.google.com/blog/products/identity-security/introducing-cas-a-cloud-based-managed-ca-for-the-devops-and-iot-world"
      }}>{`Google Cloud Certificate Authority Service`}</a>{` (CAS) instance.
We will deploy the RA as a VM instance that issues X.509 Certificates for internal services, using the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Automated_Certificate_Management_Environment"
      }}>{`ACME protocol`}</a>{`.`}</p>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://console.cloud.google.com/projectselector2/home/dashboard"
        }}>{`A Google Cloud project`}</a>{` with billing enabled`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/sdk/gcloud"
        }}>{`gcloud command line tools`}</a></li>
    </ul>
    <h2>{`Configuration Guide`}</h2>
    <h4>{`1. Create or select an existing GCP project and enable `}<inlineCode parentName="h4">{`CAS`}</inlineCode>{` APIs.`}</h4>
    <p>{`  For the purposes of this example, lets use the project ID `}<inlineCode parentName="p">{`smallstep-cas-test`}</inlineCode>{`.`}</p>
    <p>{`  In a console, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ export PROJECT_ID=smallstep-cas-test
$ gcloud config set project $PROJECT_ID

# Enable CAS
$ gcloud services enable privateca.googleapis.com
`}</code></pre>
    <h4>{`2. Create a CA Pool`}</h4>
    <p>{`  Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gcloud privateca pools create example-ca-pool --location us-west1
`}</code></pre>
    <h4>{`3. Create and Enable a Root CA`}</h4>
    <p>{`To create, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{` gcloud privateca roots create example-root-ca \\
     --pool example-ca-pool \\
     --location us-west1 \\
     --subject "CN=Example Root CA, O=Example LLC" \\
     --key-algorithm ec-p256-sha256 \\
     --max-chain-length 1
`}</code></pre>
  <Alert severity="info" mdxType="Alert">
    <div> 
      See <Code mdxType="Code">gcloud privateca roots create --help</Code> for more options.
      You can customize values for <Code mdxType="Code">KeyUsage</Code>, <Code mdxType="Code">MaxPathLen</Code>,
      <Code mdxType="Code">KeyAlgorithm</Code>, and more.
    </div>
  </Alert>
    <p>{`  Be sure to change the Common Name and Organization to your liking.`}</p>
    <p>{`To enable CA, run:`}</p>
    <pre><code parentName="pre" {...{}}>{`  gcloud privateca roots enable example-root-ca \\
    --pool example-ca-pool \\
    --location us-west1 
`}</code></pre>
    <h4>{`4. Create and Enable an Intermediate CA.`}</h4>
    <p>{`  To create, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gcloud privateca subordinates create example-intermediate-ca \\
    --pool example-ca-pool \\
    --location us-west1 \\
    --issuer-ca example-root-ca \\
    --issuer-pool example-ca-pool \\
    --issuer-location us-west1 \\
    --subject "CN=Example Intermediate CA, O=Example LLC" \\
    --key-algorithm ec-p256-sha256 \\
    --max-chain-length 1 
`}</code></pre>
    <p>{`  To enable, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gcloud privateca subordinates enable example-intermediate-ca \\
    --pool example-ca-pool \\
    --location us-west1
`}</code></pre>
    <h4>{`5. (Optional) Test Root and Intermediate CA`}</h4>
    <p>{`To inspect the root and intermediate CA, you need to have the following dependencies installed:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/cli/"
        }}>{`step`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stedolan.github.io/jq/"
        }}>{`jq`}</a>{` `}</li>
    </ul>
    <p>{`Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gcloud privateca roots describe example-root-ca \\
--project smallstep-cas-test --location us-west1 \\
--pool example-ca-pool --format json | jq -r '.pemCaCertificates[0]' | step certificate inspect
`}</code></pre>
    <p>{`Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gcloud privateca subordinates describe example-intermediate-ca \\
--project smallstep-cas-test --location us-west1 \\
--pool example-ca-pool --format json | jq -r '.pemCaCertificates[0]' | step certificate inspect
`}</code></pre>
    <h4>{`6. Configure a service account with necessary role bindings to request and manage X.509 Certificates using CAS.`}</h4>
    <p>{`  Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`# Create service account
gcloud iam service-accounts create step-cas-sa \\
    --description "Step-CA Service Account" \\
    --display-name "Step-CA Service Account"
# Add permissions to use the privateca API
gcloud projects add-iam-policy-binding $PROJECT_ID \\
    --member=serviceAccount:step-cas-sa@$PROJECT_ID.iam.gserviceaccount.com \\
    --role=roles/privateca.certificateManager
gcloud projects add-iam-policy-binding $PROJECT_ID \\
    --member=serviceAccount:step-cas-sa@$PROJECT_ID.iam.gserviceaccount.com \\
    --role=roles/privateca.certificateRequester
`}</code></pre>
    <h4>{`7. Launch a "Smallstep ACME Registration Authority for CAS" deployment`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Select "Smallstep ACME Registration Authority for CAS" from GCP marketplace
and hit the `}<inlineCode parentName="p">{`Launch`}</inlineCode>{` button.`}</p>
        <p parentName="li">{`  `}<img parentName="p" {...{
            "src": "/graphics/cas-launch-image.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Fill in required fields in the Launcher UI.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/cas-required-fields.png",
            "alt": null
          }}></img></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Service Account`}</strong>{`: email address of the service account generated in the previous step.
This value should be equivalent to `}<inlineCode parentName="p">{`step-cas-sa@<PROJECT_ID>.iam.gserviceaccount.com`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`CAS Certificate Authority ID`}</strong>{`: name of the CAS intermediate Certificate
Authority created above.`}</p>
            <p parentName="li">{`  Get this value from the command line:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-shell"
              }}>{`gcloud privateca subordinates describe example-intermediate-ca --pool example-ca-pool --location us-west1 --format='get(name)'
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Optional fields in the Launcher UI.`}</p>
        <p parentName="li">{`Click the small `}<inlineCode parentName="p">{`more`}</inlineCode>{` button to expand optional fields.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/cas-optional-fields.png",
            "alt": null
          }}></img></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`RA DNS Name`}</strong>{`: use this field to configure a permanent DNS name for your
ACME RA. By default, this value will be the internal hostname of the instance.
Hostnames are permanent within GCP so this value will not change across
host restarts.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`RA Address`}</strong>{`: use this field to configure the address and port at
which the ACME RA will listen for connections. By default this value is
`}<inlineCode parentName="p">{`0.0.0.0:443`}</inlineCode>{`. Note that, although the RA is configured to listen on all
interfaces, external HTTP and HTTPS traffic is not enabled by default.
See the `}<a parentName="p" {...{
                "href": "#next-steps"
              }}>{`next steps`}</a>{` section for more info.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deploy!`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/cas-deploy.png",
            "alt": null
          }}></img></p>
      </li>
    </ol>
    <h4>{`8. Let's test out our deployment!`}</h4>
    <p>{`In this example we'll use `}<a parentName="p" {...{
        "href": "https://certbot.eff.org/"
      }}>{`certbot`}</a>{` to test our
new ACME RA for CAS, but any popular ACME client should work.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a VM instance within the same project as your Smallstep ACME RA for CAS deployment.`}</p>
        <p parentName="li">{`For the purposes of this example set the following attributes in the instance
configuration:`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Name`}</strong>{`: `}<inlineCode parentName="li">{`ra-tester`}</inlineCode></li>
          <li parentName="ul"><strong parentName="li">{`Boot disk`}</strong>{`: `}<inlineCode parentName="li">{`Ubuntu 20.04 LTS`}</inlineCode></li>
          <li parentName="ul"><strong parentName="li">{`Scopes`}</strong>{`: Allow full access to all Cloud APIs`}</li>
        </ul>
        <p parentName="li">{`All other configuration can remain as is.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`SSH to the `}<inlineCode parentName="p">{`ra-tester`}</inlineCode>{` instance and install `}<inlineCode parentName="p">{`certbot`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell",
            "metastring": "nocopy",
            "nocopy": true
          }}>{`$ sudo snap install core; sudo snap refresh core
$ sudo snap install --classic certbot

# Execute the following instruction on the command line on the machine to
# ensure that the certbot command can be run.
$ sudo ln -s /snap/bin/certbot /usr/bin/certbot
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Download the root certificate.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`gcloud privateca roots describe example-root-ca --pool example-ca-pool --location us-west1 --format='get(pemCaCertificates)' > /tmp/root_ca.crt
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a new certificate using the ACME RA for CAS.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`# Save the internal hostname for which we'll issue the certificate
$ export HOST=$(curl http://metadata.google.internal/computeMetadata/v1/instance/hostname --silent --fail -H "Metadata-Flavor: Google")

# Save the project-id
$ export PROJECT_ID=$(curl http://metadata.google.internal/computeMetadata/v1/project/project-id --silent --fail -H "Metadata-Flavor: Google")

# Get a certificate!
$ sudo REQUESTS_CA_BUNDLE=/tmp/root_ca.crt certbot certonly -n --standalone -d $HOST --server https://acme-registration-authority-for-gcp-c-1-vm.c.$PROJECT_ID.internal/acme/acme/directory --agree-tos --email tester@smallstep.com
`}</code></pre>
        <p parentName="li">{`Hooray!`}</p>
      </li>
    </ol>
    <h2>{`Next Steps`}</h2>
    <p>{`A few considerations for making your ACME RA for CAS production ready.`}</p>
    <h3>{`Security Hardening`}</h3>
    <p>{`VM instance configuration:`}</p>
    <ul>
      <li parentName="ul">{`Block project-wide SSH keys`}</li>
      <li parentName="ul">{`We recommend leaving HTTP and HTTPS traffic from the internet diabled (this is the default).
However, if your CA `}<em parentName="li">{`must`}</em>{` be publicly available you should be aware that any user with
a publicly addressable domain will be able to request a certificate from your CA. If
you'd like to learn about options for locking down permissable ACME domains please
contact Smallstep Support | `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`support@smallstep.com`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      